var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('va-modal',{attrs:{"size":"large","hide-default-actions":true,"title":'Email Log'},model:{value:(_vm.showEmail),callback:function ($$v) {_vm.showEmail=$$v},expression:"showEmail"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"flex xs12 md6 px-2"},[_c('va-input',{attrs:{"label":"Sent to","disabled":true},model:{value:(_vm.selectedEmail.to),callback:function ($$v) {_vm.$set(_vm.selectedEmail, "to", $$v)},expression:"selectedEmail.to"}})],1),_c('div',{staticClass:"flex xs12 md6 px-2"},[_c('va-input',{attrs:{"label":"Send Date","disabled":true},model:{value:(_vm.selectedEmail.date),callback:function ($$v) {_vm.$set(_vm.selectedEmail, "date", $$v)},expression:"selectedEmail.date"}})],1),_c('VueJsonPretty',{attrs:{"data":_vm.selectedEmail}})],1)]),_c('va-card',{attrs:{"title":"Mail Log"}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"row"},[_c('va-checkbox',{attrs:{"label":'Show Failed Emails Only'},on:{"input":_vm.updateTable},model:{value:(_vm.showFailedOnly),callback:function ($$v) {_vm.showFailedOnly=$$v},expression:"showFailedOnly"}})],1)]),_c('div',{staticClass:"row align--center"},[_c('div',{staticClass:"flex xs12 md6"},[_c('va-input',{attrs:{"placeholder":'Search by name or email',"removable":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}},[_c('va-icon',{attrs:{"slot":"prepend","name":"fa fa-search"},slot:"prepend"})],1)],1),_c('div',{staticClass:"flex xs12 md3 offset--md3"},[_c('va-select',{attrs:{"label":_vm.$t('tables.perPage'),"options":_vm.perPageOptions,"no-clear":""},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)]),(_vm.notSearching)?_c('va-data-table',{attrs:{"fields":_vm.fields,"data":_vm.mailLogs,"api-mode":"","per-page":parseInt(_vm.perPage),"clickable":"","hoverable":"","totalPages":_vm.totalPages,"loading":_vm.loading},on:{"row-clicked":(row) => {
          _vm.selectedEmail = row;
          _vm.showEmail = true;
        },"page-selected":_vm.handlePageChange},scopedSlots:_vm._u([{key:"status",fn:function(props){return [(
            props.rowData.delivery &&
            props.rowData.delivery.state === 'SUCCESS'
          )?_c('span',[_c('i',{staticClass:"fa fa-check"})]):(props.rowData.delivery)?_c('span',[_vm._v(" "+_vm._s(props.rowData.delivery.state)+" ")]):_c('span',[_vm._v(" In Transit ")])]}}],null,false,4019533144)}):_c('div',[_c('va-card',[_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-spinner fa-spin fa-3x fa-fw"})]),_c('va-progress-bar',{attrs:{"value":50,"buffer":100}},[_c('p',{staticClass:"py-2"},[_vm._v("Refreshing Logs, "),_c('b',{staticStyle:{"color":"rgb(85, 25, 25)"}},[_vm._v("This may take a few seconds")])])])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }