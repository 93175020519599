<template>
  <div>
    <va-modal
      v-model="showEmail"
      size="large"
      :hide-default-actions="true"
      :title="'Email Log'"
    >
      <div class="row">
        <div class="flex xs12 md6 px-2">
          <va-input
            v-model="selectedEmail.to"
            label="Sent to"
            :disabled="true"
          />
        </div>
        <div class="flex xs12 md6 px-2">
          <va-input
            v-model="selectedEmail.date"
            label="Send Date"
            :disabled="true"
          />
        </div>
        <VueJsonPretty :data="selectedEmail" />
      </div>
    </va-modal>
    <va-card title="Mail Log">
      <template slot="actions">
        <div class="row">
          <va-checkbox
            :label="'Show Failed Emails Only'"
            @input="updateTable"
            v-model="showFailedOnly"
          />
        </div>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            v-model="term"
            :placeholder="'Search by name or email'"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            no-clear
          />
        </div>
      </div>
      <va-data-table
        v-if="notSearching"
        :fields="fields"
        :data="mailLogs"
        api-mode
        :per-page="parseInt(perPage)"
        @row-clicked="
          (row) => {
            selectedEmail = row;
            showEmail = true;
          }
        "
        clickable
        hoverable
        :totalPages="totalPages"
        @page-selected="handlePageChange"
        :loading="loading"
      >    
        <template slot="status" slot-scope="props">
          <span
            v-if="
              props.rowData.delivery &&
              props.rowData.delivery.state === 'SUCCESS'
            "
          >
            <i class="fa fa-check" />
          </span>
          <span v-else-if="props.rowData.delivery">
            {{ props.rowData.delivery.state }}
          </span>
          <span v-else> In Transit </span>
        </template>
      </va-data-table>
      <div v-else>
        <va-card>
            <div class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>
            <va-progress-bar
              :value="50"
              :buffer="100"
            >
              <p class="py-2">Refreshing Logs, <b style="color: rgb(85, 25, 25);">This may take a few seconds</b></p>
            </va-progress-bar>
        </va-card>
      </div>
    </va-card>
  </div>
</template>
<script>
import firebase from "firebase";
import moment from "moment";
import { debounce } from "lodash";
import VueJsonPretty from "vue-json-pretty";
import axios from '@/scripts/interceptor.js'
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data: function () {
    return {
      loading: true,
      log: [],
      showEmail: false,
      showFailedOnly: false,
      selectedEmail: {},
      perPage: "10",
      term: "",
      perPageOptions: ["10", "50", "100", "250"],
      mailLogs: [],
      cachedPages: {},
      notSearching: true,
      pageSnapshots: [],
      currentPage: 1,
      term: "",
      totalPages: 1,
    };
  },
  async created() {
    await this.getPagination();
    await this.fetchEmailLogs(this.currentPage, this.perPage);
  },
  watch: {
    term: {
      handler: function () {
        console.log("Term changed");
        this.debouncedSearch();
      },
    },
    currentPage: {
      handler: function () {
        if(!this.notSearching) return;
        this.fetchEmailLogs(this.currentPage, this.perPage);
      },
    },
    perPage: {
      handler: async function () {
        this.resetCache();
        await this.getPagination();
        if (this.term) {
          this.searchEmailLogs(this.term, 0, this.perPage);
        } else {
          await this.fetchEmailLogs(this.currentPage, this.perPage);
        }
      },
    },
  },
  methods: {
    async searchEmailLogs(searchTerm, page, itemsPerPage) {
      this.loading = true;
      try {
        const result = await axios.post(window.firebaseURL + `api/admin/mail/search`,{
          search: searchTerm,
          hitsPerPage: parseInt(itemsPerPage),
          page: page,
        });
        let fetchedExams = []
        for (const doc of result?.data?.data.hits) {
          const data = doc;
          data.id = doc.id;
          data.date = this.parseDate(doc?.delivery?.startTime);
          data.parsedSubject = this.getSubject(data);
          fetchedExams.push(data);
        }
        this.mailLogs = fetchedExams;
        this.totalPages = result?.data?.data?.totalPages;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    debouncedSearch: _.debounce(async function () {
      if (this.term) {
        console.log("Searching for", this.term);
        this.notSearching = false;
        await this.searchEmailLogs(this.term, 0, this.perPage);
        console.log("Search completed");
        this.notSearching = true;
      } else {
        this.notSearching = false;
        this.resetCache();
        await this.getPagination();
        await this.fetchEmailLogs(1, this.perPage);
        this.notSearching = true;
      }
    }, 500),
    async updateTable() {
      this.notSearching = false;
      this.currentPage = 1;
      await this.resetCache();
      await this.getPagination();
      await this.fetchEmailLogs(this.currentPage, this.perPage);
      this.notSearching = true;
    },
    filterAndSortUsers(exams, showFailed) {
      if (!exams) {
        return [];
      }
      if (showFailed) {
        return exams.filter((exam) => {
          return exam.delivery && exam.delivery.state !== "SUCCESS";
        });
      }
    },
    resetCache() {
      this.cachedPages = {};
      this.pageSnapshots = [];
    },
    async getPagination() {
      let examsTable = firebase.firestore().collection("email");
      if (this.showFailedOnly) {
        examsTable = examsTable.where("delivery.state", "!=", "SUCCESS");
      }
      let querySnapshot = await examsTable.get();
      const totalItems = querySnapshot.size;
      const itemsPerPage = parseInt(this.perPage);
      this.totalPages = Math.floor(totalItems / itemsPerPage);
    },
    async fetchEmailLogs(page, itemsPerPage, search = "") {
      this.loading = true;
      this.mailLogs = [];
      const pageSize = parseInt(itemsPerPage);
      const targetPage = page;

      // Check if the requested page is already in the cache
      if (this.cachedPages[targetPage] && !search) {
        this.mailLogs = this.cachedPages[targetPage];
        this.loading = false;
        return;
      }

      let examsTable = firebase.firestore().collection("email");
      let examsQuery = "";
      if (this.showFailedOnly) {
        examsTable = examsTable.where("delivery.state", "!=", "SUCCESS");
        examsQuery = examsTable
          .orderBy("delivery.state", "desc")
          .limit(pageSize);
      } else {
        examsQuery = examsTable
          .orderBy("delivery.startTime", "desc")
          .limit(pageSize);
      }

      // Use the cached boundaries, if available
      if (targetPage > 1) {
        if (this.pageSnapshots[targetPage - 1]) {
          // Reuse the stored snapshot for the previous page
          examsQuery = examsQuery.startAfter(
            this.pageSnapshots[targetPage - 1].end
          );
        } else {
          const skips = (targetPage - 1) * pageSize;
          let lastVisible = "";
          if (this.showFailedOnly) {
            lastVisible = await examsTable
              .limit(skips)
              .get()
              .then((snapshot) => {
                return snapshot.docs[snapshot.docs.length - 1];
              });
          } else {
            lastVisible = await examsTable
              .orderBy("delivery.startTime", "desc")
              .limit(skips)
              .get()
              .then((snapshot) => {
                return snapshot.docs[snapshot.docs.length - 1];
              });
          }

          examsQuery = examsQuery.startAfter(lastVisible);
        }
      }

      let querySnapshot = await examsQuery.get();

      const fetchedExams = [];

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        data.id = doc.id;
        data.date = this.parseDate(data.delivery.startTime);
        data.parsedSubject = this.getSubject(data);
        fetchedExams.push(data);
      }
      this.cachedPages[targetPage] = fetchedExams;
      this.mailLogs = fetchedExams;
      this.pageSnapshots[targetPage] = {
        start: querySnapshot.docs[0],
        end: querySnapshot.docs[querySnapshot.docs.length - 1],
      };
      this.loading = false;
    },
    handlePageChange(page) {
      if (this.term) {
        this.searchEmailLogs(this.term, page - 1, this.perPage);
      } else {
        this.currentPage = page;
      }
    },
    getSubject(log) {
      try {
        let subject = "No Subject";
        if (log.message && log.message.subject) {
          subject = log.message.subject;
        }
        if (log.template) {
          if (log.template.name) {
            subject = "Template: " + log.template.name;
          }
        }

        return subject;
      } catch (e) {
        console.log("Error parsing log", log);
        console.error(e);
      }
    },
    parseDate(date) {
      try {
        let seconds = date.seconds ?? date;
        return moment(new Date(seconds * 1000)).format(
          "MM/D/YYYY, h:mm:ss a"
        );
      } catch (e) {
        return "Error parsing date... Probably in transit";
      }
    },
    successParse(data) {
      let returnData = '<i class="fa fa-check" />';
      if (data.state !== "SUCCESS") {
        returnData = data.state;
      }
      return returnData;
    },
  },
  computed: {
    filteredData() {
      if ((!this.term || this.term.length < 1) && !this.showFailedOnly) {
        return this.mailLogs;
      }

      return this.mailLogs.filter((item) => {
        let returnLog = false;
        // if (item.delivery) {
        //     const dataString = JSON.stringify(item.delivery);
        //   if (dataString.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
        //     returnLog = true;
        //   }
        // }
        if (item.to.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
          returnLog = true;
        }
        if (
          item.parsedSubject.toLowerCase().indexOf(this.term.toLowerCase()) !==
          -1
        ) {
          returnLog = true;
        }
        if (item.date.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
          returnLog = true;
        }
        if (
          this.showFailedOnly &&
          item.delivery &&
          item.delivery.state === "SUCCESS"
        ) {
          returnLog = false;
        }

        return returnLog;
      });
    },
    fields() {
      return [
        {
          name: "id",
          title: "ID",
        },
        {
          name: "to",
          title: "Receiver",
          sortField: "to",
        },
        {
          name: "parsedSubject",
          title: "Subject / Template",
          sortField: "parsedSubject",
        },

        {
          name: "__slot:status",
          title: "Status",
        },
        {
          name: "date",
          title: "Date",
          sortField: "date",
        },
      ];
    },
  },
};
</script>
